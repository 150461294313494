import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import "./course.css";
import ProductCard from "../../Components/ProductCard/ProductCard";
const Courses = () => {
  return (
    <>
      <Breadcrumb name={"Course"} pageName={"course"} />
      <section className="all_courses p-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="courses__sidebar_area">
                <div class="courses__sidebar_area">
                  <div class="courses__sidebar_button d-lg-none">
                    <h4>filter</h4>
                  </div>
                  <aside class="courses__sidebar">
                    <div class="courses-widget">
                      <h4 class="widget-title">Categories</h4>
                      <div class="courses-cat-list">
                        <ul class="list-wrap">
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="business"
                                id="cat_2"
                              />
                              <label class="form-check-label" for="cat_2">
                                Business
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="design"
                                id="cat_5"
                              />
                              <label class="form-check-label" for="cat_5">
                                Design
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="development"
                                id="cat_1"
                              />
                              <label class="form-check-label" for="cat_1">
                                Development
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="finance"
                                id="cat_8"
                              />
                              <label class="form-check-label" for="cat_8">
                                Finance
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="it-software"
                                id="cat_3"
                              />
                              <label class="form-check-label" for="cat_3">
                                IT &amp; Software
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="marketing"
                                id="cat_6"
                              />
                              <label class="form-check-label" for="cat_6">
                                Marketing
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="music"
                                id="cat_7"
                              />
                              <label class="form-check-label" for="cat_7">
                                Music
                              </label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input
                                class="form-check-input main-category-checkbox"
                                type="radio"
                                name="main_category"
                                value="personal-developemnt"
                                id="cat_4"
                              />
                              <label class="form-check-label" for="cat_4">
                                Productivity
                              </label>
                            </div>
                          </li>
                        </ul>
                        <div class="show-more"></div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="row">
                <div className="col-md-6">
                  <ProductCard />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Courses;
