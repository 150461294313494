import React, { useEffect, useState } from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/image/logo.png";

function Header() {
  const location = useLocation();

  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 160) {
      // Adjust the scroll position where the header becomes sticky
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerMenu = [
    {
      title: "Home",
      path: "/",
      subMenu: false,
    },
    // {
    //   title: "Services",
    //   path: "",
    //   subMenu: [
    //     {
    //       title: "EHR Software Development",
    //       path: "/",
    //       subMenu: false,
    //     },
    //     {
    //       title: "Design Engineering",
    //       path: "/",
    //       subMenu: [
    //         {
    //           title: "Figma",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Adobe XD",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Adobe Illustrator",
    //           path: "/",
    //           subMenu: false,
    //         },
    //       ],
    //     },

    //     {
    //       title: "Mobile App Development",
    //       path: "/",
    //       subMenu: [
    //         {
    //           title: "iOS app development services",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Android App Development services",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Flutter Mobile App Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "React Native App Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //       ],
    //     },
    //     {
    //       title: "Front End Development",
    //       path: "/",
    //       subMenu: [
    //         {
    //           title: "React JS",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Vue JS",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Next JS",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Angular",
    //           path: "/",
    //           subMenu: false,
    //         },
    //       ],
    //     },
    //     {
    //       title: "Web Application Development",
    //       path: "/",
    //       subMenu: [
    //         {
    //           title: "PHP Development services",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Web Development Services",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "eCommerce Website Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "EHR Development Services",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Mean and Mern stack Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //       ],
    //     },
    //     {
    //       title: "CMS & Ecommerce",
    //       path: "/",
    //       subMenu: [
    //         {
    //           title: "Shopify App Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Drupal Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "Magento Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //         {
    //           title: "WordPress Development",
    //           path: "/",
    //           subMenu: false,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      title: "Course",
      path: "/course",
      subMenu: false,
    },
    {
      title: "About Us",
      path: "/about-us",
      subMenu: false,
    },
    {
      title: "Instructors",
      path: "/instructors",
      subMenu: false,
    },
    // {
    //   title: "Blog",
    //   path: "/blog",
    //   subMenu: false,
    // },
    {
      title: "Contact Us",
      path: "/contact-us",
      subMenu: false,
    },
  ];

  return (
    <header className={isSticky ? "sticky-menu" : ""}>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              {headerMenu?.map((item, index) => {
                return (
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === item.path ? "active" : ""
                      } navbar_link`}
                      to={item.path}
                      id={item.title}
                      role="button"
                    >
                      {item.title}{" "}
                      {item.subMenu && <i className="fas fa-angle-down"></i>}
                    </Link>

                    {item.subMenu && (
                      <ul
                        className="dropdown-menu sub_first"
                        aria-labelledby={item.title}
                      >
                        {item.subMenu &&
                          item.subMenu?.map((subFirst, index) => {
                            return (
                              <li className="nav-item dropdown dropdown-item">
                                <Link
                                  className={`${
                                    location.pathname === "service"
                                      ? "active"
                                      : ""
                                  } navbar_link `}
                                  to="/"
                                  id={subFirst.title}
                                  role="button"
                                >
                                  {subFirst.title}{" "}
                                  {subFirst.subMenu && (
                                    <i class="fas fa-angle-right"></i>
                                  )}
                                </Link>

                                {subFirst.subMenu && (
                                  <ul
                                    className="dropdown-menu sub_sec"
                                    aria-labelledby={subFirst.title}
                                  >
                                    {subFirst.subMenu &&
                                      subFirst.subMenu?.map((subSec, index) => {
                                        return (
                                          <li>
                                            <Link
                                              className={`${
                                                location.pathname === "service"
                                                  ? "active"
                                                  : ""
                                              } navbar_link dropdown-item`}
                                              to=""
                                            >
                                              {subSec.title}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                );
              })}

              {/* <li className="nav-item">
                <Link
                  // className="navbar_link"
                  to="/"
                  className={`${
                    location.pathname === "/" ? "active" : ""
                  } navbar_link`}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={`${
                    location.pathname === "service" ? "active" : ""
                  } navbar_link dropdown-toggle`}
                  to="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="nav-item dropdown dropdown-item">
                    <Link
                      className={`${
                        location.pathname === "service" ? "active" : ""
                      } navbar_link dropdown-toggle`}
                      to="/"
                      id="navbarDropdown2"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </Link>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                      <li>
                        <Link
                          className={`${
                            location.pathname === "service" ? "active" : ""
                          } navbar_link dropdown-item`}
                          to=""
                        >
                          Action
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`${
                            location.pathname === "service" ? "active" : ""
                          } navbar_link dropdown-item`}
                          to=""
                        >
                          Action
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={`${
                            location.pathname === "service" ? "active" : ""
                          } navbar_link dropdown-item`}
                          to=""
                        >
                          Action
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "service" ? "active" : ""
                      } navbar_link dropdown-item`}
                      to=""
                    >
                      Action
                    </Link>
                  </li>

                  <li>
                    <Link
                      className={`${
                        location.pathname === "service" ? "active" : ""
                      } navbar_link dropdown-item`}
                      to=""
                    >
                      Action
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/winners" ? "active" : ""
                  } navbar_link`}
                  to="/winners"
                >
                  Our Clients
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/blog" ? "active" : ""
                  } navbar_link`}
                  to="/blog"
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`${
                    location.pathname === "/contactUs" ? "active" : ""
                  } navbar_link`}
                  to="/contactUs"
                >
                  Contact Us
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="call_box">
            {/* <ul>
              <li className="contact">
                <i className="fas fa-phone"></i>
                <p>
                  Call us today!
                  <strong>
                    <a href="tel:%2B91%207899916434%20">+91 7899916434 </a>
                  </strong>
                </p>
              </li>
            </ul> */}
            <ul className="list-wrap">
              <li className="mini-cart-icon">
                <a href="#" className="cart-count">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    data-inject-url="https://skillgro.websolutionus.com/frontend/img/icons/cart.svg"
                    className="injectable"
                  >
                    <path
                      d="M19.4853 6.2598C19.2241 5.93213 18.8323 5.74415 18.4102 5.74415H14.6011L12.5355 1.01447C12.4075 0.721229 12.0659 0.587219 11.7726 0.715357C11.4793 0.843418 11.3454 1.18503 11.4735 1.47831L13.3365 5.74419H6.44249L8.30546 1.47831C8.43352 1.18503 8.29963 0.843457 8.00634 0.715357C7.7131 0.587219 7.37148 0.721152 7.24342 1.01447L5.17787 5.74419H1.36873C0.946649 5.74419 0.554817 5.93213 0.293635 6.25984C0.0372424 6.58155 -0.0573642 6.99513 0.0340748 7.39465L2.06825 16.2797C2.2103 16.9001 2.75913 17.3334 3.40291 17.3334H16.376C17.0198 17.3334 17.5686 16.9001 17.7107 16.2797L19.7449 7.39461C19.8363 6.99509 19.7417 6.58151 19.4853 6.2598ZM16.376 16.1745H3.40291C3.30452 16.1745 3.21833 16.1099 3.19797 16.021L1.16379 7.13602C1.14784 7.06629 1.17461 7.01383 1.19995 6.98211C1.22344 6.9526 1.27663 6.90311 1.36873 6.90311H4.67177L4.51999 7.25068C4.39193 7.54396 4.52583 7.88553 4.81911 8.01363C4.8946 8.04662 4.97325 8.06223 5.0507 8.06223C5.27406 8.06223 5.48692 7.93231 5.58203 7.71455L5.93639 6.90319H13.8426L14.197 7.71455C14.2921 7.93235 14.505 8.06223 14.7283 8.06223C14.8057 8.06223 14.8844 8.04662 14.9599 8.01363C15.2532 7.88557 15.3871 7.54396 15.259 7.25068L15.1073 6.90311H18.4103C18.5024 6.90311 18.5556 6.9526 18.5791 6.98211C18.6044 7.01387 18.6312 7.06633 18.6152 7.13598L16.5811 16.0211C16.5607 16.1099 16.4744 16.1745 16.376 16.1745Z"
                      fill="currentcolor"
                    ></path>
                    <path
                      d="M6.41271 9.41407C6.09269 9.41407 5.83324 9.67352 5.83324 9.99354V14.2429C5.83324 14.5629 6.09269 14.8224 6.41271 14.8224C6.73272 14.8224 6.99217 14.5629 6.99217 14.2429V9.99354C6.99217 9.67352 6.73276 9.41407 6.41271 9.41407Z"
                      fill="currentcolor"
                    ></path>
                    <path
                      d="M9.88947 9.41407C9.56946 9.41407 9.31001 9.67352 9.31001 9.99354V14.2429C9.31001 14.5629 9.56946 14.8224 9.88947 14.8224C10.2095 14.8224 10.4689 14.5629 10.4689 14.2429V9.99354C10.4689 9.67352 10.2095 9.41407 9.88947 9.41407Z"
                      fill="currentcolor"
                    ></path>
                    <path
                      d="M13.3662 9.41407C13.0462 9.41407 12.7868 9.67352 12.7868 9.99354V14.2429C12.7868 14.5629 13.0462 14.8224 13.3662 14.8224C13.6863 14.8224 13.9457 14.5629 13.9457 14.2429V9.99354C13.9457 9.67352 13.6863 9.41407 13.3662 9.41407Z"
                      fill="currentcolor"
                    ></path>
                  </svg>
                  <span className="mini-cart-count">0</span>
                </a>
              </li>
              <li className="mini-cart-icon user_icon">
                <a href="javascript:;" className="cart-count">
                  <img
                    src="https://skillgro.websolutionus.com/frontend/img/icons/menu_user.svg"
                    alt="img"
                  />
                </a>
                <ul className="menu_user_list">
                  <li>
                    <Link to={"/login"}>Sign In</Link>
                  </li>
                  <li>
                    <Link to={"/register"}>Sign Up</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
