import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import "./terms.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="terms">
        <Breadcrumb name={"Privacy Policy"} pageName={"Privacy Policy"} />
        <div className="terms-area ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="terms-info-wrap">
                  <h4 className="title">Privacy Policy</h4>

                  <ul className="list-wrap">
                    <li>
                      <div className="content">
                        <p>
                          {" "}
                          Users agree to the terms by accessing or using the
                          website/app.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="content">
                        <p>
                          {" "}
                          The company reserves the right to change the terms at
                          any time.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="content">
                        <p>
                          {" "}
                          Users must follow applicable laws and not misuse the
                          service.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
